@media (max-width: 768px) {
  .footer-container {
    padding-top: 20px;
    background-color: #2D2D2F;
    padding-bottom: 10px;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-white {
    color: #fff;
  }

  .set-cursor {
    cursor: pointer;
  }
}
@media (min-width: 768px) {
  .footer-container {
    padding-top: 20px;
    background-color: #2d2d2f;
    padding-bottom: 10px;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-white {
    color: #fff;
  }

  .set-cursor {
    cursor: pointer;
  }

  .sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
  }
}
