.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.product-card {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
}

.product-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.product-info {
  text-align: center;
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
